'use client';

import { Avatar, Tooltip } from '@/lib/primer';
import type { UserInfo } from '@fidant-io/auth-core-adapter/oidc';

export type Props = Omit<React.ComponentProps<typeof Avatar>, 'src' | 'alt'> & {
  user: UserInfo | null;
  status?: 'authenticated' | 'unauthenticated' | 'loading' | null;
};

export default function AvatarView(props: Props) {
  const { user, status, ...avatarProps } = props;
  const { name = '??', picture = 'https://github.com/octocat.png' } = user ?? {};
  // The role="button" is required because the Tooltip component requires a single interactive child.
  return (
    <Tooltip text={JSON.stringify(user, null, 2)} direction="sw">
      <span role="button">
        <Avatar src={picture} alt={`@${name}`} {...avatarProps} />
        &nbsp;{name}
        {status && status !== 'authenticated' && <> ({status})</>}
      </span>
    </Tooltip>
  );
}
