'use client';

import { isNotFoundError } from 'next/dist/client/components/not-found';
import React, { Suspense, useMemo } from 'react';
import type { ErrorBoundaryProps, FallbackProps } from 'react-error-boundary';
import { ErrorBoundary } from 'react-error-boundary';

import { hasAuthenticationError } from '@fidant-io/api-client/AuthenticationError';

/** @import { AuthenticationError } from '@fidant-io/api-client/AuthenticationError'; */

type ErrorComponent = React.ComponentType<FallbackProps>;

// This is why this module is marked 'use client':
// > Functions cannot be passed directly to Client Components unless you explicitly expose it by marking it with "use server"

const mkErrorComponent = ({ name, fallback = null }: { name: string; fallback?: React.ReactNode }): ErrorComponent => {
  function ErrorComponent({ error }: { error: Error & { digest?: string }; resetErrorBoundary: () => void }) {
    if (hasAuthenticationError(error)) {
      return fallback;
    }
    if (isNotFoundError(error)) {
      throw error;
    }
    const { name, message, stack, digest, ...errorRest } = error;
    return (
      <pre style={{ whiteSpace: 'break-spaces', wordBreak: 'break-word', wordWrap: 'normal' }}>
        {error.name}: {JSON.stringify({ digest, name, message, ...errorRest, stack }, null, 2)}
      </pre>
    );
  }
  return Object.defineProperty(ErrorComponent, 'displayName', { value: `ErrorComponent(${name})` });
};

export interface SuspenseOrAuthBoundaryProps extends Pick<ErrorBoundaryProps, 'onError' | 'onReset'> {
  /**
   * The name of the suspense boundary for displaying in the React DevTools.
   */
  name: string;

  /**
   * The placeholder to render during suspense.
   */
  fallback?: React.ReactNode;

  /**
   * The node to render when an {@link AuthenticationError|authentication error} is caught.
   *
   * @default fallback
   */
  authFallback?: React.ReactNode;

  /**
   * Values which will trigger a reset of the error boundary when changed.
   */
  resetKeys?: readonly unknown[];
}

export const SuspenseOrAuthBoundary = ({
  name,
  children,
  fallback,
  authFallback = fallback,
  onError,
  onReset,
  resetKeys,
}: React.PropsWithChildren<SuspenseOrAuthBoundaryProps>) => {
  const ErrorComponent = useMemo(() => mkErrorComponent({ name, fallback: authFallback }), [authFallback, name]);

  // https://react.dev/reference/react/Suspense#providing-a-fallback-for-server-errors-and-client-only-content
  // "Suspense" acts as special error boundary; errors thrown in server mode are swallowed and retried on client.
  // Because of that, I wonder if it makes sense to prefer to catch "real" errors inside the ErrorBoundary, avoiding the
  // Suspense retry. But common wisdom (aka brief googling) suggests that ErrorBoundary should be higher in the tree.
  return (
    <ErrorBoundary
      FallbackComponent={ErrorComponent}
      {...(onError && { onError })}
      {...(onReset && { onReset })}
      {...(resetKeys?.length && { resetKeys: resetKeys as unknown[] })}
    >
      <Suspense name={name} fallback={fallback}>
        {children}
      </Suspense>
    </ErrorBoundary>
  );
};
