import { isErrorLike } from '@fidant-io/util/error';
function* iterErrorMembers(e) {
    // Error with a cause.
    if (typeof e.cause === 'object' && e.cause !== null) {
        yield e.cause;
    }
    // AggregateError (or similar).
    if (e.errors != null && Array.isArray(e.errors)) {
        yield* e.errors;
    }
    // GraphQLError
    if (e.originalError != null && typeof e.originalError === 'object') {
        yield e.originalError;
    }
    // CombinedError
    if (e.networkError != null && typeof e.networkError === 'object') {
        yield e.networkError;
    }
}
/**
 * Walk an error and its causes, yielding each error unique ErrorLike encountered.
 * Traverses the error chain breadth-first.
 * @param root
 */
export function* walkError(root, maxDepth = Infinity) {
    if (maxDepth === 0) {
        return;
    }
    const seen = new Set([root]);
    const toVisit = [[1, root]];
    while (toVisit.length > 0) {
        const [depth, v] = toVisit.shift();
        if (!isErrorLike(v)) {
            continue;
        }
        const e = v;
        yield e;
        if (depth >= maxDepth) {
            continue;
        }
        for (const ce of iterErrorMembers(e)) {
            if (!seen.has(ce)) {
                seen.add(ce);
                toVisit.push([depth + 1, ce]);
            }
        }
    }
}
